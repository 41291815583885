<template>
     <div class="page-wrap">
      <div class="page-aside">
        <el-menu
        background-color="#1a2031"
        text-color="#fff"
        active-text-color="#4d85d9"
        :default-active=$route.path
        :router="true"
      >
          <el-menu-item index="/datamanage/dataupload" >数据上传</el-menu-item>
          <el-menu-item index="/datamanage/datauploadmanage">数据管理</el-menu-item>
          <el-menu-item index="/datamanage/factormanage" >因子管理</el-menu-item>
      </el-menu>
       
      </div>
      <div class="page-main flex-column">
        <div class="main"><router-view></router-view></div>
      </div>  
    </div> 

</template>
<script>
export default {
   name:'datamanage',
   data(){
        return {

        }
   },
   methods:{
    // changeTarget(){
    //     this.$router.push("/datamanage/DataUploadManage")
    // },
   }
}
</script>


